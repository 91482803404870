import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {Howl} from 'howler';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const audioClips = [
  {sound: "https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/01%20Glad%20You're%20Here%20MASTER.mp3?alt=media&token=bc2359dc-3bea-4c9f-9856-d7342b9ecacf", label: 'glad youre here'}
]


class home extends Component {
  soundPlay = (src) => {
    const sound = new Howl ({
        src,
        html5: true
    });
    
    sound.play();
}

RenderButtonSound = () => {
    return audioClips.map((soundObj, index) => {
        return(
            <Card>
                <button key={index} onClick={() => this.soundPlay(soundObj.sound)}>
                {soundObj.label}
                </button>
                <CardContent>
                    <Typography variant="h4" align="right">
                        don't click that button again
                    </Typography>
                    <Typography variant="body1" align="right">
                        (just kidding...its broken)
                    </Typography>
                </CardContent>     
            </Card>
        )
    })
}

render() {
    //const { classes, song : {artistName, songName, albumName, genre, audioUrl } } = this.props;
    
    
    return (
        this.RenderButtonSound()
    )
};
}

export default home;


//songMarkup contains all of the urls to the songs in the database. I can't play the audio with ReactPlayer
//because it's expecting only 1 url (at least that might be the problem). I would need a different ReactPlayer
//for each url. What if I count how many songs, create a for loop that makes ReactPlayers for all available songs.
//Eh probably won't work.

/* <Grid item sm={8} xs={10}>
<h1>MOOBLY KREW!!!!!!!!!!!!!</h1>
</Grid>
<Grid item sm={4} xs={10}>
  <body2>wowowoowowowowow</body2>
</Grid>
*/