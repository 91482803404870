import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from './util/theme';

//Components
import Navbar from './components/layout/Navbar.js';

//Pages
import home from './Pages/home';
import about from './Pages/about';
import jscovell from './Pages/jscovell';

const theme = createMuiTheme(themeFile);

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
            <Router>
                <div className="container">
                <Navbar />
                  <Switch>
                    <Route exact path="/" component={home} />
                    <Route exact path="/about" component={about} />
                    <Route exact path="/jscovell" component={jscovell} />
                  </Switch>
                </div>
            </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
