import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';

//MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

//Icons
import HomeIcon from '@material-ui/icons/Home';

class Navbar extends Component {
  render() {
    return (
      <AppBar color="white">
        <Toolbar className="nav-container">
            <Fragment>
                <Button color="black" component={Link} to="/about">MOOBLY</Button>
                <Button color="black" component={Link} to="/">KREW</Button>
           </Fragment>  
        </Toolbar>
      </AppBar>
  
    )
  }
}

export default Navbar;
