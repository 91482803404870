import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';


class about extends Component {
  
  render() {
    
    return (
      <Grid container spacing={10}>
        <Grid item sm={8} xs ={10}>
          <h1>sheitt</h1>
        </Grid>
        <Grid item sm={4} xs={10}>
          <body2>hehe</body2>
        </Grid>
      </Grid>
    );
  }
}

export default about;
