export default {
  palette: {
      primary: {
        light: '#00e676',
        main: '#00c853',
        dark: '#212121',
        contrastText: '#fff'
      },
      secondary: {
        light: '#00b0ff',
        main: '#0091ea',
        dark: '#004d40',
        contrastText: '#fff'
      }
    },
    typography: {
      useNextVariants: true
    },
    form: {
        textAlign: 'center'
      },
    image: {
      margin: '20px auto 20px auto',
      width: '20%'
    },
    pageTitle: {
      margin: '10 auto 10 auto'
    },
    textField: {
      margin: '10 auto 10 auto'
    },
    button: {
      marginTop: 20,
      position: 'relative'
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: 10
    },
    progress: {
      position: "absolute"
    },
    invisibleSeparator: {
      border: 'none',
      margin: 4
    },
    visibleSeparator: {
      width: '100%',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
      marginBotton: 20
    }
};