import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';

class jscovell extends Component {
  
  render() {
    
    return (
        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
        >
        <Grid item ={3}>
            <h1>Official John Scovell EPK: 2023</h1>
        </Grid>   
        <Grid item = {1}>
            <body>The work of the artist known as John Scovell insinuates a necessity to
                all that is righteous and just. All that is holy and pure.
            </body>
        </Grid>

        <Paper variant="outlined">
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
         <img width={100} src="https://firebasestorage.googleapis.com/v0/b/mooblykrew3.appspot.com/o/jscovell.png?alt=media&token=0d9f596c-0a89-4638-9de3-1af64f6c269a" />
        </Paper>

        <Grid item = {1}>
            <h2>#################################
            </h2>
        </Grid>

        </Grid>
      
    );
  }
}


export default jscovell;
